
#pain-level-selector-id,
#interval-selector-id {
  ion-chip {
    scale: 1.5
  }
  #selected {
    scale: 3;
    margin-top: 30px;
  }
  .hint {
    font-size: initial;
  }
}