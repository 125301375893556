ion-menu {
/*--ion-background-color: var(--ion-color-light);
  --ion-text-color: var(--ion-color-light-contrast);

  ion-toolbar {
    --background: var(--ion-color-light);
  }*/

  ion-list {/* optional, but it needs when you use gradient as a background color.*/
    background: transparent;
  }
}


#logo-container {
  height: 57px;
  display: flex;
  background-color: var(--ion-color-third);
  cursor: pointer;
}

body.dark {
  ion-menu {
    --ion-background-color: var(--ion-color-dark);
    --ion-text-color: var(--ion-color-light);

    ion-toolbar {
      --background: transparent;
    }

  }
  #logo-container {
    background-color: var(--ion-color-dark);
  }
}
