/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root, [data-amplify-theme="telebinoid-theme"] {
  /** primary **/
  --tel-color-primary-100: hsl(172, 100%, 15%);
  --tel-color-primary-90: hsl(172, 100%, 20%);
  --tel-color-primary-80: hsl(172, 95%, 30%);
  --tel-color-primary-60: hsl(172, 50%, 50%);
  --tel-color-primary-40: hsl(172, 70%, 70%);
  --tel-color-primary-20: hsl(172, 75%, 85%);
  --tel-color-primary-10: hsl(172, 75%, 95%);
	--ion-color-primary: hsl(172, 41%, 44%);
	--ion-color-primary-rgb: -75,-75,-75;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #-42-42-42;
	--ion-color-primary-tint: #-2a-2a-2a;

  /** secondary **/
  --tel-color-secondary-100: hsl(20, 100%, 15%);
  --tel-color-secondary-90: hsl(20, 100%, 20%);
  --tel-color-secondary-80: hsl(20, 95%, 30%);
  --tel-color-secondary-60: hsl(20, 50%, 50%);
  --tel-color-secondary-40: hsl(20, 70%, 70%);
  --tel-color-secondary-20: hsl(20, 75%, 85%);
  --tel-color-secondary-10: hsl(20, 75%, 95%);
	--ion-color-secondary: hsl(20, 83%, 50%);
	--ion-color-secondary-rgb: 203,203,203;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #b3b3b3;
	--ion-color-secondary-tint: #d0d0d0;

  /** third **/
  --tel-color-third-100: hsl(40, 100%, 15%);
  --tel-color-third-90: hsl(40, 100%, 20%);
  --tel-color-third-80: hsl(40, 95%, 30%);
  --tel-color-third-60: hsl(40, 50%, 50%);
  --tel-color-third-40: hsl(40, 70%, 70%);
  --tel-color-third-20: hsl(40, 75%, 85%);
  --tel-color-third-10: hsl(40, 75%, 95%);
	--ion-color-third: hsl(40, 91%, 82%);
	--ion-color-third-rgb: 223,223,223;
	--ion-color-third-contrast: #000000;
	--ion-color-third-contrast-rgb: 0,0,0;
	--ion-color-third-shade: #c4c4c4;
	--ion-color-third-tint: #e2e2e2;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** typeform **/
  --ion-color-typeform: #7dba92;
	--ion-color-typeform-rgb: 125,186,146;
	--ion-color-typeform-contrast: #000000;
	--ion-color-typeform-contrast-rgb: 0,0,0;
	--ion-color-typeform-shade: #6ea480;
	--ion-color-typeform-tint: #8ac19d;

  /* Amplify overrides */
  --amplify-colors-brand-secondary-100: var(--amplify-colors-purple-100);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-purple-90);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-purple-80);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-purple-60);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-purple-40);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-purple-20);
  --amplify-colors-brand-secondary-10: var(--amplify-colors-purple-10);

  --amplify-colors-brand-primary-100: var(--tel-color-primary-100) !important;
  --amplify-colors-brand-primary-90: var(--tel-color-primary-90) !important;
  --amplify-colors-brand-primary-80: var(--tel-color-primary-80) !important;
  --amplify-colors-brand-primary-60: var(--tel-color-primary-60) !important;
  --amplify-colors-brand-primary-40: var(--tel-color-primary-40) !important;
  --amplify-colors-brand-primary-20: var(--tel-color-primary-20) !important;
  --amplify-colors-brand-primary-10: var(--tel-color-primary-10) !important;

}

.ion-color-third {
	--ion-color-base: var(--ion-color-third);
	--ion-color-base-rgb: var(--ion-color-third-rgb);
	--ion-color-contrast: var(--ion-color-third-contrast);
	--ion-color-contrast-rgb: var(--ion-color-third-contrast-rgb);
	--ion-color-shade: var(--ion-color-third-shade);
	--ion-color-tint: var(--ion-color-third-tint);
}

.ion-color-primary-80 {
	--ion-color-base: var(--tel-color-primary-80);
	--ion-color-base-rgb: var(--ion-color-primary-rgb);
	--ion-color-contrast: var(--ion-color-primary-contrast);
	--ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
	--ion-color-shade: var(--ion-color-primary-shade);
	--ion-color-tint: var(--ion-color-primary-tint);
}

.ion-color-typeform {
	--ion-color-base: var(--ion-color-typeform);
	--ion-color-base-rgb: var(--ion-color-typeform-rgb);
	--ion-color-contrast: var(--ion-color-typeform-contrast);
	--ion-color-contrast-rgb: var(--ion-color-typeform-contrast-rgb);
	--ion-color-shade: var(--ion-color-typeform-shade);
	--ion-color-tint: var(--ion-color-typeform-tint);
}

/*
   * Dark Colors
   * -------------------------------------------
   */

body.dark {
  --ion-color-primary: #5FD0E7;
  --ion-color-primary-rgb: 95,208,231;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #54b7cb;
  --ion-color-primary-tint: #6fd5e9;

  --ion-color-secondary: #EFBE71;
  --ion-color-secondary-rgb: 239,190,113;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #d2a763;
  --ion-color-secondary-tint: #f1c57f;

  --ion-color-third: hsl(40, 11%, 42%);
  --ion-color-third-rgb: 223,223,223;
  --ion-color-third-contrast: #000000;
  --ion-color-third-contrast-rgb: 0,0,0;
  --ion-color-third-shade: #c4c4c4;
  --ion-color-third-tint: #e2e2e2;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106,100,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47,223,117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255,213,52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255,73,97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244,245,248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0,0,0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34,36,40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255,255,255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --amplify-colors-background-primary: black;
}

/*
* iOS Dark Theme
* -------------------------------------------
*/

.ios body.dark {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0,0,0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255,255,255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-item-background: #000000;

  --ion-card-background: #1c1c1d;

  ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
  }
}

/*
* Material Design Dark Theme
* -------------------------------------------
*/

.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18,18,18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255,255,255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #1e1e1e;

}

ion-button, ion-segment-button {
  text-transform: none;
}

button.alert-button.alert-button-delete {
  background-color: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
}