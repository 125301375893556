div[id^="react-select-0"],
div[id^="react-select-1"],
div[id^="react-select-2"],
div[id^="react-select-3"],
div[id^="react-select-4"],
div[id^="react-select-5"],
div[id^="react-select-6"],
div[id^="react-select-7"],
div[id^="react-select-8"],
div[id^="react-select-9"] {
  z-index: 1000;
}