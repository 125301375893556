
.flex-container {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}