@import './pages/landing/libs/vars';
@import './pages/landing/libs/functions';
@import './pages/landing/libs/mixins';
@import './pages/landing/libs/vendor';
@import './pages/landing/libs/breakpoints';

@include breakpoints((
  xlarge:  ( 1281px,  1680px ),
  large:   ( 981px,   1280px ),
  medium:  ( 737px,   980px  ),
  small:   ( 361px,   736px  ),
  xsmall:  ( null,    360px  )
));

.required:after {
  content:" *";
  color: red;
}

.text-bold {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.tr-header {
  background: rgba(0,0,0,.04) !important;
}

hr {
  border-bottom: 1px solid #ccc;
  margin: 10px 0;
  width: 100%;
}

.copyright {
  font-size: 75%;
  direction: ltr;
}

.tr-header {
  background: rgba(0,0,0,.04) !important;
  border-bottom: 1px solid rgba(0,0,0, .2);
}

.tr-warning {
  background: var(--ion-color-warning);
}

ion-icon {
  pointer-events: none;
}