@import '../landing/libs/breakpoints';

@include breakpoints((
  xlarge:  ( 1281px,  1680px ),
  large:   ( 981px,   1280px ),
  medium:  ( 737px,   980px  ),
  small:   ( 361px,   736px  ),
  xsmall:  ( null,    360px  )
));

#booking-page {

  .calendly-inline-widget {
    overflow: hidden !important;
  }

  ion-content {

    height: 100vh;
    //--background: url('../../assets/img/booking-bg-large.png') no-repeat center/cover;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: left;
    font-size: 1.3em;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: left;
    flex-direction: column;
    gap: 15px;
    padding-left: 30px;
    padding-right: 20px;
  }
  #booking-slide-one {
    gap: 0px;
    @include breakpoint('<=small') {
      ion-label {
        font-size: 0.8em;
      }
    }
  }

  @include breakpoint('<=small') {

    #booking-slide-zero {
      font-size: 0.9em;
    }
    .swiper-slide {
      text-align: left;
      font-size: 1em;
    }

    #booking-slide-firstName {
      font-size: 1em;
      gap: 5px;
      padding-top: 120px;
      justify-content: flex-start;
    }

    #booking-slide-email {
      font-size: 0.9em;
      gap: 5px;
      padding-top: 120px;
      justify-content: flex-start;
    }

    #booking-slide-bloodThinningMedication {
      gap: 0;
    }

    .swiper-slide {
      visibility: hidden;
    }

    .swiper-slide.swiper-slide-active {
      visibility: visible;
    }

  }

  #booking-slide-calendly {
    padding: 0;
    gap: 5px;
  }

}

:root {
  --swiper-theme-color: var(--ion-color-primary);
}

.navigation-button-container {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 2;
  @include breakpoint('<=xsmall') {
    @media all and (display-mode: browser) {
      bottom: 60px;
    }
  }
}