#quize-chronic-pain,
#quize-dass-21 {
  ion-content {
    height: 100vh;
  }
  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    text-align: left;
    font-size: 1.3em;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: left;
    flex-direction: column;
    gap: 15px;
  }
  .body-part-front {
    font-size: initial;
    color: rgb(151, 151, 151);
    padding-right: 65px;
  }
  .body-part-back {
    font-size: initial;
    color: rgb(151, 151, 151);
    padding-left: 65px;
    
  }
}